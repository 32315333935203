import { render, staticRenderFns } from "./SuspendModal.vue?vue&type=template&id=773de67e&scoped=true&"
import script from "./SuspendModal.vue?vue&type=script&lang=js&"
export * from "./SuspendModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "773de67e",
  null
  
)

export default component.exports
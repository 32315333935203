<template>
  <v-dialog :value="visible" persistent width="600">
    <v-card>
      <v-card-title>
        Price per user
      </v-card-title>
      <v-card-text>
        <v-text-field type="number" v-model="price_per_user"></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="$emit('close')">Close</v-btn>
        <v-btn @click="$emit('save', price_per_user)">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "PriceModal",
  data() {
    return {
      price_per_user: 0
    }
  },
  watch: {
    tenant() {
      this.price_per_user = this.tenant.price_per_user
    }
  },
  props: {
    tenant: {
      type: Object,
      default() {
        return {}
      }
    },
    value: {
      type: Boolean,
      default: false
    },
    visible: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <v-dialog :value="visible" :width="300">
    <v-card>
      <v-card-title>
        <template v-if="tenant.suspended_at">Activate?</template>
        <template v-else>Suspend?</template>
      </v-card-title>
      <v-card-text>
        Are you sure?
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="$emit('close')">Close</v-btn>
        <v-btn @click="$emit('save', tenant)">
          <template v-if="tenant.suspended_at">Activate</template>
          <template v-else>Suspend</template>
        </v-btn>
      </v-card-actions>
    </v-card>

  </v-dialog>
</template>

<script>
export default {
  name: "SuspendModal",
  props: {
    tenant: {
      type: Object,
      default() {
        return {}
      }
    },
    visible: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <v-row>
    <v-col>
      <v-row>
        <v-col>
          <v-row align="center">
            <v-col><h2>Admins</h2></v-col>
            <v-col align="right">
              <v-btn @click.stop="openUsersModal">New admin</v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-data-table :headers="headers" dense :items="admins"
                            :loading="dataLoading" :items-per-page="-1">
                <template v-slot:top>
                </template>
                <template v-slot:item.email="{ item }">
                  <a @click.prevent="openEditModal(item)">{{ item.email }}</a>
                </template>
                <template v-slot:item.created_at="{ item }">
                  {{ item.created_at | formatDate }}
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-btn text @click="openEditModal(item)" :disabled="currentUser.id == item.id">Edit</v-btn>
                  <v-btn text @click="deleteUser(item)" :disabled="currentUser.id == item.id">Delete</v-btn>
                </template>
              </v-data-table>
            </v-col>
          </v-row>

        </v-col>
      </v-row>

      <v-dialog
          v-model="visible"
          persistent
          max-width="600px">
        <v-card>
          <v-card-title><span class="text-h5">Add user</span></v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col>
                  <v-text-field type="email" v-model="newUser.email" label="Email" hide-details required
                                :disabled="!!newUser.id || progressImport"/>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field type="password" v-model="newUser.password" label="Password" hide-details required
                                :disabled="progressImport"/>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="visible = false" :disabled="progressImport">
              Close
            </v-btn>
            <v-btn color="blue darken-1" text @click="createUser" :disabled="!!!newUser.email || !!!newUser.password"
                   :loading="progressImport">
              <template v-if="newUser.id">Update</template>
              <template v-else>Create</template>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>
<script>

import {mapState, mapActions} from 'vuex'

export default {
  computed: {
    ...mapState('auth', ['currentUser'])
  },
  methods: {
    async deleteUser(user) {
      if (window.confirm(`Delete ${user.email}?`)) {
        await this.$api.delete(`admins/${user.id}`)
        this.loadAdmins()
      }
    },
    async loadAdmins() {
      this.dataLoading = true
      let resp = await this.$api.get('admins')
      this.admins = resp.data
      this.dataLoading = false
    },
    ...mapActions('users', ['setOptions', 'filterUsers']),
    resetFilters() {
      this.filter = {}
      this.filterUsers({})
    },
    async createUser() {
      this.progressImport = true
      try {
        if (this.newUser.id) {
          await this.$api.put(`admins/${this.newUser.id}`, this.newUser)
        } else {
          await this.$api.post(`admins`, this.newUser)
        }
        this.loadAdmins()
        this.visible = false
      } catch (e) {
        alert(e.response.data.errors)
      } finally {
        this.progressImport = false
      }
    },
    openEditModal(user) {
      this.newUser = Object.assign({}, user)
      this.visible = true
    },
    openUsersModal() {
      this.newUser = {}
      this.visible = true
    },
    contactIcon(contact) {
      switch (contact.status) {
        case 'ok':
          return 'mdi-check'
        case 'progress':
          return 'mdi-loading mdi-spin'
        case 'error':
          return 'mdi-alert-circle'
        default:
          return 'mdi-timer-sand'
      }
    },
    icon(user) {
      if (user.id) {
        return 'mdi-check'
      } else {
        return user.errors ? 'mdi-alert-circle' : 'mdi-loading mdi-spin'
      }
    },
  },
  data() {
    return {
      admins: [],
      emails: '',
      loading: false,
      dataLoading: false,
      search: '',
      importDialog: false,
      hubspotProgress: false,
      importProgress: 0,
      listUrl: '',
      list: null,
      filter: {},
      headers: [
        {
          text: 'ID',
          value: 'id',
          key: 'id',
        },
        {
          text: 'Email',
          value: 'email',
          key: 'email',
        },
        {
          text: 'Created',
          value: 'created_at',
          key: 'created_at',
        }, {
          text: 'Actions',
          value: 'actions'
        },
      ],
      visible: false,
      newUsers: [],
      newUser: {},
      progressImport: false

    }
  },
  async created() {
    await this.loadAdmins()
  }
}
</script>

import Vue from 'vue'
import axios from 'axios'
import store from '../store'
import router from '../router'

let api = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  maxRedirects: 0,
  withCredentials: true,
  headers: {
    'Accept': 'application/json'
  }
})
api.interceptors.request.use(function (config) {
  const token = localStorage.getItem('ag_admin_auth');
  if(token)
    config.headers.Authorization = token
  return config;
});
api.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if(error.response.status == 401) {
    store.commit('auth/setLoggedOut')
    router.push({name: 'Login'})
  }
  return Promise.reject(error);
});

Vue.prototype.$axios = api
Vue.prototype.$api = api

export default api

<template>
  <v-container>
    <v-card flat>
      <v-card-title>Goals</v-card-title>
      <v-card-text>
        <v-simple-table>
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-left">
                ID
              </th>
              <th class="text-left">
                Email
              </th>
              <th class="text-left">
                Name
              </th>
              <th class="text-left">
                Created
              </th>
              <th class="text-left">
                Submitted
              </th>
              <th class="text-left">
                Type
              </th>
              <th class="text-left">
                Report
              </th>
            </tr>
            </thead>
            <tbody>
            <tr
                v-for="goal in user.goals"
                :key="goal.id"
            >
              <td>{{ goal.id }}</td>
              <td>{{ user.email }}</td>
              <td>{{ user.name }}</td>
              <td>{{ goal.created_at | formatDate }}</td>
              <td>{{ goal.created_at | formatDate }}</td>
              <td>{{ goal.goal_type == 'personal' ? 'Personal' : 'Professional' }}</td>
              <td>
                <v-btn :disabled="downloading" text small @click="downloadReport(goal)">Download</v-btn>
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>

  </v-container>
</template>
<script>
import {uniq, trim, flatten} from 'lodash'
import {mapState} from "vuex";

export default {
  computed: {
    ...mapState('user', ['user'])
  },
  methods: {
    async downloadReport(goal) {
      this.downloading = true
      try {
        let url = `goals/${goal.id}`

        const response = await this.$api.get(url, {responseType: "blob"});
        const blob = new Blob([response.data], {type: "application/pdf"});
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = `Report_${goal.id}`;
        // link.target = '_blank'
        link.click();
        URL.revokeObjectURL(link.href);
      } catch (e) {
        alert('Report downloading failed!')
      } finally {
        this.downloading = false
      }
    },
    openUsersModal() {
      this.newUsers = []
      this.emails = ''
      this.visible = true

    },
    icon(user) {
      if (user.id) {
        return 'mdi-check'
      } else {
        return user.errors ? 'mdi-alert-circle' : 'mdi-loading mdi-spin'
      }
    },
    newUser() {
      this.newUsers = []
      this.visible = true
    },
    async importUsers() {
      this.progressImport = true
      let emails = uniq(
          this.emails.split("\n")
              .map(e => trim(e))
              .filter(e => e.length > 0)
      )
      this.newUsers = emails.map(e => {
        return {
          email: e,
          error: false,
          errors: null,
          id: null
        }
      })
      this.newUsers.forEach(async (user, index) => {
        console.log(index)
        try {
          let res = await this.$api.post('users', {email: user.email})
          console.log(res)
          user.id = res.data.id
        } catch (e) {
          user.error = true
          user.errors = flatten(Object.values(e.response.data))
        } finally {
          if (index == this.newUsers.length - 1)
            this.progressImport = false
        }
      })

    },
    async save() {
      try {
        await this.$api.post('users', this.user)
        this.visible = false
      } finally {
        this.loading = false
      }

    }
  },
  data() {
    return {
      emails: '',
      downloading: false,
      loading: false,
      search: '',
      headers: [
        {
          text: 'ID',
          value: 'id'
        },
        {
          text: 'Email',
          value: 'email'
        },
        {
          text: 'Goals',
          value: 'goals'
        },

        {
          text: 'Created',
          value: 'created_at'
        },
      ],
      visible: false,
      users: [],
      newUsers: [],
      progressImport: false

    }
  },
  async created() {
    let resp = await this.$api.get('users')
    this.users = resp.data
  }
}
</script>

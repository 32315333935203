<template>
  <!--  <v-container full-height fluid>-->
  <v-row>
    <v-col>
      <v-row>
        <v-col>
          <v-row align="center">
            <v-col><h2>Tenants</h2></v-col>
            <v-col align="right">
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-data-table :headers="headers" dense :items="tenants" @update:options="setOptions" item-key="name"
                            :options="options"
                            :loading="dataLoading" must-sort
                            :server-items-length="meta.total_count"
                            :footer-props="{
                'itemsPerPageOptions': [20, 50, 100]
                            }"
              >
                <template v-slot:top>
                </template>
                <template v-slot:item.email="{ item }">
                  <router-link :to="{name: 'User', params:{id: item.id}}">{{ item.email }}</router-link>
                </template>
                <template v-slot:item.users_count="{ item }">
                  <v-btn small text @click="openUsersModal(item)">{{ item.users_count }}</v-btn>
                </template>
                <template v-slot:item.notes="{ item }">
                  <p v-if="item.notes" style="cursor: pointer; margin: 0" @click="openNotesModal(item)">
                    {{ item.notes }}</p>
                  <v-btn text v-else x-small @click="openNotesModal(item)">Add a note</v-btn>
                </template>
                <template v-slot:item.price_per_user="{ item }">
                  <v-btn small text v-if="item.price_per_user" @click="openPriceModal(item)">
                    $ {{ item.price_per_user }}
                  </v-btn>
                  <v-btn text v-else x-small @click="openPriceModal(item)">Set price</v-btn>
                </template>
                <template v-slot:item.created_at="{ item }">
                  {{ item.created_at | formatDate }}
                </template>
                <template v-slot:item.payment_due="{ item }">
                  <template v-if="item.payment_due">
                    {{ item.payment_due | formatDateOnly }}
                  </template>
                </template>
                <template v-slot:item.paid_at="{ item }">
                  <template v-if="item.paid_at">
                    {{ item.paid_at | formatDateOnly }}
                  </template>
                </template>
                <template v-slot:item.to_pay="{ item }">
                  $ {{ item.users_count * item.price_per_user }}
                </template>
                <template v-slot:item.invoice_sent="{ item }">
                  <template v-if="item.invoice_sent">
                    {{ item.invoice_sent | formatDateOnly }}
                    <br>
                    <v-btn x-small @click="openInvoicesModal(item)">Invoices</v-btn>
                  </template>
                  <v-btn text small @click="openInvoicesModal(item)" v-else>
                    Invoices
                  </v-btn>
                </template>
                <template v-slot:item.suspended_at="{ item }">
                  <template v-if="item.suspended_at">
                    {{ item.suspended_at | formatDate }}
                    <br>
                    <v-btn x-small @click="openSuspendModal(item)">Activate</v-btn>
                  </template>

                  <v-btn x-small text v-else @click="openSuspendModal(item)">Suspend</v-btn>
                </template>
              </v-data-table>
            </v-col>
          </v-row>

        </v-col>
        <!--        <v-col cols="2">-->
        <!--          <h2>Filters</h2>-->
        <!--          <v-text-field placeholder="Email" label="Email" tabindex="1" v-model="filter.email"/>-->
        <!--          <v-text-field placeholder="Name" label="Name" tabindex="2" v-model="filter.name"/>-->
        <!--          <v-row>-->
        <!--            <v-col>-->
        <!--              <v-btn tabindex="3" @click="filterTenants(filter)">Filter</v-btn>-->
        <!--            </v-col>-->
        <!--            <v-col>-->
        <!--              <v-btn tabindex="4" @click="resetFilters">Clear filters</v-btn>-->
        <!--            </v-col>-->
        <!--          </v-row>-->
        <!--        </v-col>-->
      </v-row>
      <v-dialog v-model="linkDialog" persistent max-width="700px">
        <v-card>
          <v-card-title><span class="text-h5">Password reset link</span></v-card-title>
          <v-card-text>
            <v-row>
              <v-col>
                <v-text-field :value="link" readonly>
                  <template v-slot:append-outer>
                    <v-btn small @clickq="copyLink" v-clipboard:copy="link">Copy</v-btn>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn text @click="linkDialog=false" :disabled="progressImport">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

    </v-col>
    <NotesModal :tenant="currentTenant" :visible="notesModal" @close="notesModal=false" @save="saveNotes"/>
    <SuspendModal :tenant="currentTenant" :visible="suspendModal" @close="suspendModal=false" @save="suspend"/>
    <PriceModal :tenant="currentTenant" :visible="priceModal" @close="priceModal=false" @save="savePrice"/>
    <UsersModal :users="users" :visible="usersModal" @close="usersModal=false"/>
    <InvoicesModal :invoices="invoices" :tenant="currentTenant" :visible="invoicesModal" @close="closeInvoicesModal"/>
  </v-row>
</template>
<script>

import {mapState, mapActions} from 'vuex'
import NotesModal from "@/components/NotesModal";
import PriceModal from "@/components/PriceModal";
import UsersModal from "@/components/UsersModal";
import SuspendModal from "@/components/SuspendModal";
import InvoicesModal from "@/components/InvoicesModal";

export default {
  components: {SuspendModal, NotesModal, PriceModal, UsersModal, InvoicesModal},
  computed: {
    ...mapState('tenants', ['tenants', 'dataLoading', 'meta', 'options',
      'users', 'invoices']),
  },
  methods: {
    ...mapActions('tenants', ['setOptions', 'filterTenants', 'setNotes', 'setPrice', 'load',
      'loadUsers', 'toggleSuspend', 'loadInvoices']),
    closeInvoicesModal() {
      this.invoicesModal = false
      this.load()
    },
    openUsersModal(tenant) {
      this.loadUsers(tenant.id)
      this.usersModal = true
    },
    openPriceModal(tenant) {
      this.priceModal = true
      this.currentTenant = tenant
    },
    openNotesModal(tenant) {
      this.notesModal = true
      this.currentTenant = tenant
    },
    openSuspendModal(tenant) {
      this.suspendModal = true
      this.currentTenant = tenant
    },
    openInvoicesModal(tenant) {
      this.invoicesModal = true
      this.currentTenant = tenant
      this.loadInvoices(tenant.id)
    },
    suspend() {
      this.suspendModal = false
      this.toggleSuspend(this.currentTenant)
    },
    saveNotes(notes) {
      this.notesModal = false
      this.setNotes({id: this.currentTenant.id, notes: notes})
    },
    savePrice(price) {
      this.priceModal = false
      this.setPrice({id: this.currentTenant.id, price: price})
    },
    async deleteUser(id) {
      if (confirm('Are you sure?')) {
        await this.$store.dispatch('tenants/delete', id)
      }
    },
    resetFilters() {
      this.filter = {}
      this.filterTenants({})
    },

  },
  data() {
    return {
      link: '',
      linkDialog: false,
      emails: '',
      loading: false,
      suspendModal: false,
      notesModal: false,
      priceModal: false,
      usersModal: false,
      invoicesModal: false,
      currentTenant: {},
      search: '',
      importDialog: false,
      hubspotProgress: false,
      importProgress: 0,
      listUrl: '',
      list: null,
      filter: {},
      headers: [
        {
          text: 'ID',
          value: 'id',
          key: 'id',
        },
        {
          text: 'Admin email',
          value: 'email',
          key: 'email',
        },
        {
          text: 'Created at',
          value: 'created_at',
          key: 'created_at',
        },
        {
          text: 'Users',
          value: 'users_count'
        }, {
          text: 'Price per user',
          value: 'price_per_user'
        }, {
          text: 'Amount to pay',
          value: 'to_pay',
          sortable: false
        }, {
          text: 'Payment due',
          value: 'payment_due',
        }, {
          text: 'Invoice sent',
          value: 'invoice_sent',
        }, {
          text: 'Paid at',
          value: 'paid_at'
        },
        {
          text: 'Note',
          value: 'notes',
          sortable: false,
          width: 250
        }, {
          text: 'Suspended',
          value: 'suspended_at'
        }
      ],
      visible: false,
      progressImport: false

    }
  },
  async created() {

  }
}
</script>

<template>
  <v-dialog :value="visible" persistent fullscreen>
    <v-card>
      <v-card-title>
        Users
      </v-card-title>
      <v-card-text>
        <v-data-table :headers="headers" :items="users"
                      :items-per-page="-1"
        >
          <template v-slot:item.created_at="{ item }">
            {{ item.created_at | formatDate }}
          </template>
          <template v-slot:item.deleted_at="{ item }">
            <template v-if="item.deleted_at">{{ item.deleted_at | formatDate }}
            </template>
          </template>

        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="$emit('close')">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "UsersModal",
  data() {
    return {
      headers: [
        {
          text: 'ID',
          value: 'id'
        }, {
          text: 'Email',
          value: 'email'
        }, {
          text: 'Created at',
          value: 'created_at'
        }, {
          text: 'Role',
          value: 'roles'
        }, {
          text: 'Deactivated',
          value: 'deleted_at'
        }
      ]
    }
  },
  props: {
    users: {
      type: Array,
      default() {
        return []
      }
    },
    visible: {
      type: Boolean,
      default: false
    }
  }

}
</script>

<style scoped>

</style>
import api from '../plugins/axios'
// import router from '../router'
export default {
  state: {
    loggedIn: true,
    currentUser: null
  },
  mutations: {
    setLoggedIn(state, data) {
      state.currentUser = data
      state.loggedIn = true
    },
    setLoggedOut(state) {
      state.currentUser = {}
      state.loggedIn = false
      localStorage.removeItem('ag_admin_auth')
    }
  },
  actions: {
    async test({ commit}) {
      try{
        let resp = await api.get('profile')
        commit('setLoggedIn', resp.data)
      } catch (e) {
        commit('setLoggedOut')
      }
    },
    async login({ commit}, data) {
      let resp = await api.post('sign_in', {...data, ...{admin_user: data}})
      commit('setLoggedIn', resp.data)
      localStorage.setItem('ag_admin_auth', resp.headers.authorization)
    },
    async logout({commit}) {
      let resp = await api.delete('sign_out')
      commit('setLoggedOut', resp.data)
    },
  },
  namespaced: true
}

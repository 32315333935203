<template>
  <v-app>
    <v-app-bar
        app
        color="primary"
        dark
    >
      <div class="d-flex align-center">
        <v-btn to="/tenants" text>
          <span class="mr-2">Tenants</span>
        </v-btn>
      </div>
      <v-spacer></v-spacer>
      <v-btn to="/admins" text>
        <span class="mr-2">Admins</span>
      </v-btn>
      <v-btn @click="logOut" text>Logout</v-btn>
    </v-app-bar>

    <v-main>
      <v-container full-height fluid>
        <router-view/>
      </v-container>

    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',
  methods:{
    logOut(){
      this.$store.dispatch('auth/logout')
      this.$router.push({name: 'Login'})
    }
  },
  data: () => ({
    //
  }),
};
</script>

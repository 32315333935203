<template>
  <div class="login">
    <br>
    <br>
    <br>
    <br>
    <v-row class="text-center">
      <v-spacer></v-spacer>
      <v-col cols="4">
        <v-card>
          <v-card-title>Login</v-card-title>
          <v-card-text>

        <v-form @submit.prevent="login">
          <v-text-field v-model="email" type="email">

          </v-text-field>
          <v-text-field v-model="password" type="password">

          </v-text-field>
          <v-btn @click="login">Login</v-btn>
        </v-form>
          </v-card-text>
        </v-card>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>

  </div>

</template>

<script>

export default {

  data() {
    return {
      email: 'admin@example.com',
      password: 'Pa$$w0rd!'
    }
  },
  methods: {
    async login() {
      try {
        await this.$store.dispatch('auth/login', {
          email: this.email,
          password: this.password
        })
        this.$router.push({name: 'Tenants'})
      } catch (e) {
        alert('Wrong email or password')
        this.password = ''
      }
    }
  }
}
</script>

<style scoped>

</style>

import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Tenants from '../views/Tenants.vue'
import Admins from '../views/Admins.vue'
import User from '../views/User.vue'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login
  }, {
    path: '/',
    name: 'Home',
    component: Home,
    beforeEnter: async (to, from, next) => {
      await store.dispatch('auth/test')
      if (store.state.auth.loggedIn)
        next()
      else
        next({name: 'Login'})
    },
    children: [
      {
        path: '/admins',
        name: 'Admins',
        component: Admins
      },

      {
        path: '/tenants',
        name: 'Tenants',
        component: Tenants,
        beforeEnter: async (to, from, next) => {
          if(store.state.tenants.initialized == false) {
            // await store.dispatch('tenants/load')
          }
          next()
        },

      },
      {
        path: '/users/:id',
        name: 'User',
        component: User,
        beforeEnter: async (to, from, next) => {
          await store.dispatch('user/load', to.params.id)
          next()
        }
      },
      {
        path: '',
        redirect: {name: 'Tenants'}
      },
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

import api from '../plugins/axios'

export default {
    namespaced: true,
    state: {
        initialized: false,
        users: [],
        invoices: [],
        tenants: [],
        meta: {},
        options: {
            sortBy: ['id'],
            sortDesc: [true],
        },
        dataLoading: false,
        filters: {}
    },
    actions: {
        async setNotes({dispatch}, {id, notes}) {
            await api.put(`tenants/${id}`, {
                notes
            })
            dispatch('load')
        },
        async toggleSuspend({dispatch}, {id, suspended_at}) {
            await api.put(`tenants/${id}`, {
                suspended_at: suspended_at ? null : new Date()
            })
            dispatch('load')
        },
        async setPrice({dispatch}, {id, price}) {
            await api.put(`tenants/${id}`, {
                price_per_user: price
            })
            dispatch('load')
        },
        async delete({dispatch}, id) {
            await api.delete(`tenants/${id}`)
            dispatch('load')
        },
        async resetPassword(id) {
            let resp = await api.post(`tenants/${id}/password`)
            return resp.data.link
        },
        filterTenants({state, dispatch}, filters) {
            state.filters = filters
            state.options.page = 1
            dispatch('load')
        },
        setOptions({dispatch, commit}, options) {
            commit('SET_OPTIONS', options)
            dispatch('load')
        },
        async load({state, commit}) {
            state.dataLoading = true
            let resp = await api.get(`tenants`, {params: {...state.options, ...state.filters}})
            commit('SET_TENANTS', resp.data.tenants)
            commit('SET_META', resp.data.meta)
            state.dataLoading = false
            state.initialized = true
        },
        async loadUsers({state}, id) {
            state.users = []
            let resp = await api.get(`tenants/${id}/users`)
            state.users = resp.data
        },
        async loadInvoices({state}, id) {
            state.invoices = []
            let resp = await api.get(`tenants/${id}/invoices`)
            state.invoices = resp.data
        },
        async saveInvoice({dispatch}, {tenant, invoice}) {
            if (invoice.id) {
                await api.put(`tenants/${tenant.id}/invoices/${invoice.id}`, invoice)
            } else {
                await api.post(`tenants/${tenant.id}/invoices`, invoice)
            }
            await dispatch('loadInvoices', tenant.id)
        }
    },
    mutations: {
        SET_OPTIONS(state, options) {
            state.options = options
        },
        SET_TENANTS(state, tenants) {
            state.tenants = tenants
        },
        SET_META(state, meta) {
            state.meta = meta
        }

    }
}

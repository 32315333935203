import { render, staticRenderFns } from "./NotesModal.vue?vue&type=template&id=28df3afe&scoped=true&"
import script from "./NotesModal.vue?vue&type=script&lang=js&"
export * from "./NotesModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28df3afe",
  null
  
)

export default component.exports
<template>
  <v-dialog :value="visible" persistent fullscreen>
    <v-card>
      <v-card-title>
        Invoices&nbsp;&nbsp;
        <v-btn small color="primary" @click="openInvoiceModal({})">New invoice record</v-btn>
      </v-card-title>
      <v-card-text>
        <v-dialog v-model="modal" :width="500">
          <v-card>
            <v-card-title>
              Invoice
            </v-card-title>
            <v-card-text>
              <v-form ref="form">
                <v-text-field label='Amount' type="number" v-model="invoice.amount"
                              :rules="rules.amount"/>
                <v-text-field label='Payment due' type="date" v-model="invoice.payment_due"
                              :rules="rules.payment_due"/>
                <v-text-field label='Paid at' type="date" v-model="invoice.paid_at"/>
                <v-text-field label='Link' type="text" v-model="invoice.link"/>
                <v-textarea label='Notes' type="date" v-model="invoice.notes"/>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="modal=false" :disabled="loading">Close</v-btn>
              <v-btn color="primary" @click="save" :loading="loading">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-data-table :headers="headers" :items="invoices"
                      :items-per-page="-1">
          <template v-slot:item.created_at="{ item }">
            {{ item.created_at | formatDateOnly }}
          </template>
          <template v-slot:item.link="{ item }">
            <a v-if="item.link" :href="item.link" target="_blank">Open</a>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn small text @click="openInvoiceModal(item)">Edit</v-btn>
          </template>

        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="$emit('close')">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "InvoicesModal",
  methods: {
    ...mapActions('tenants', ['saveInvoice']),
    async save() {
      this.loading = true
      if (this.$refs.form.validate()) {
        await this.saveInvoice({tenant: this.tenant, invoice: this.invoice})
        this.modal = false
        this.loading = false
      }
    },
    openInvoiceModal(invoice) {
      this.invoice = {...invoice};
      if (this.invoice.id == undefined) {
        this.invoice.amount = this.tenant.price_per_user * this.tenant.users_count
      }
      this.modal = true
    }
  },
  data() {
    return {
      modal: false,
      loading: false,
      invoice: {},
      rules: {
        amount: [
          v => !!v || 'Amount is required',
          v => (v && v > 0) || 'Amount must be greater then 0',
        ],
        payment_due: [
          v => !!v || 'Payment due is required'
        ]
      },
      headers: [
        {
          text: 'ID',
          value: 'id'
        }, {
          text: 'Created at',
          value: 'created_at'
        }, {
          text: 'Amount',
          value: 'amount'
        }, {
          text: 'Payment due',
          value: 'payment_due'
        }, {
          text: 'Paid at',
          value: 'paid_at'
        },{
          text: 'Link',
          value: 'link'
        },{
          text: 'Notes',
          value: 'notes',
          width: 250
        },{
          text: 'Actions',
          value: 'actions'
        }
      ]
    }
  },
  props: {
    invoices: {
      type: Array,
      default() {
        return []
      }
    },
    tenant: {
      type: Object,
      default() {
        return {}
      }
    },
    visible: {
      type: Boolean,
      default: false
    }
  }

}
</script>

<style scoped>

</style>
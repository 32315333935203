<template>
  <v-dialog :value="visible" persistent width="600">
    <v-card>
      <v-card-title>
        Note
      </v-card-title>
      <v-card-text>
        <v-textarea v-model="notes"></v-textarea>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="$emit('close')">Close</v-btn>
        <v-btn @click="$emit('save', notes)">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "NotesModal",
  data() {
    return {
      notes: undefined
    }
  },
  watch: {
    tenant() {
      this.notes = this.tenant.notes
    }
  },
  props: {
    tenant: {
      type: Object,
      default() {
        return {}
      }
    },
    value: {
      type: Boolean,
      default: false
    },
    visible: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>

</style>